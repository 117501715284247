import React, { useState, useEffect, useMemo } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody } from "reactstrap";
import classnames from "classnames";


import Sidebar from "../../common/Sidebar/Sidebar";



import closeMdl from "../../assets/images/close.png";
import comment from "../../assets/images/comment.png";

import notify from "../../assets/images/notify.png";
import sidenav from "../../assets/images/sidenav.png";
import { useNavigate } from "react-router-dom";



import { useGetRequest, usePostRequest } from "../../Services/Queries";

import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";

import { QueryKeys } from "../../Services/QueryKeys";
import { queryClient } from "../../index";

import cryptlogo from "../../assets/images/crypto-logo.png";

import Helper from "../../Services/Helper";
import io from "socket.io-client";
import Minichart from "./Minichart";

const Home = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isActive, setIsActive] = useState(false);
  const SideNavClick = () => {
    setIsActive(!isActive);
  };

  const [currencyLists, setCurrencyLists] = useState([]);
  const [homepageContent, setHomepageContent] = useState("");

  const [tabType, setTabType] = useState("all");

  const [count, setCount] = useState(0);

  const [modal1, setModal1] = useState(false);
  const toggleChat = () => setModal1(!modal1);

  const {
    loaderProperty: { setLoading },
    userInfo: { setUserId, authToken, setUserObj },
    ReserpinStatus: { setresetpin }

  } = useCustomizeContext();

  const navigate = useNavigate();

  let getCurrencyInfoObj = {
    Api: `${USER_APIS?.GET_CURRENCY_DEAILS}/${tabType}`,
    Query_Key: QueryKeys?.GET_CURRENCY_KEY,
  };

  let getUsernotifycount = {
    Api: `${USER_APIS?.GET_NOTIFICATION_COUNT}`,
    Query_Key: QueryKeys?.GET_NOTIFY_COUNT,
  };
  let { data: getCurrncyDetails } = useGetRequest(getCurrencyInfoObj);
  let { data: getUsernotifyCount } = useGetRequest(getUsernotifycount);

  useMemo(() => {
    if (getCurrncyDetails?.status === true) {
      setCurrencyLists(getCurrncyDetails?.data);
    }
  }, [getCurrncyDetails]);

  let getHomepageObj = {
    Api: USER_APIS?.GET_HOME_PAGE_CONTENT,
    Query_Key: QueryKeys?.GET_HOME_PAGE_KEY,
  };

  let { data: getHomepageContent } = useGetRequest(getHomepageObj);

  let { mutateAsync: getUserDetails } = usePostRequest(
    QueryKeys?.GET_USER_DETAILS_KEY
  );

  useMemo(() => {
    if (getHomepageContent?.status === true) {
      setHomepageContent(getHomepageContent?.data);
    }
  }, [getHomepageContent]);

  useMemo(() => {
    if (getUsernotifyCount?.status === true) {
      setCount(getUsernotifyCount?.count);
    }
  }, [getUsernotifyCount]);

  const getProfile = async () => {
    if (localStorage.getItem("crypto.com-access-key")) {
      let postObj = {
        Api: USER_APIS?.GET_USER_DETAILS,
        Payload: {},
        Type: "",
      };
      setLoading(true);
      let res = await getUserDetails(postObj);
      if (res?.status === true) {
        localStorage.setItem("uid", res?.data?.uid);
        setUserId(res?.data?.uid);

        setUserObj(res?.data);
        setresetpin(res?.data?.resetpin)
        setLoading(false);
      }
    }
  };

  getUserDetails = useMemo(() => {
    if (authToken) {
      getProfile();
    }
  }, [authToken]);

  const navigateWallet = (id) => {
    navigate(`/entrust/${id}`);
  };

  const tabChange = (tab) => {
    setLoading(true);
    if (tab == "1") {
      toggle("1");
      setTabType("all");
    } else {
      toggle("2");
      setTabType("top");
    }
    setTimeout(() => {
      queryClient?.refetchQueries([QueryKeys?.GET_CURRENCY_KEY]);
      setLoading(false);
    }, 1000);
  };

 

  useEffect(() => {
    let socketURL = Helper?.SocketBaseurl();
    const socket = io.connect(socketURL);

    socket.on("userNotifyCount", async function (data) {
      if (data?.uid == localStorage.getItem("uid")) {
        setCount(data?.count);
      }
    });
    return ()=>{
      socket.disconnect()
    }
    
  }, []);

  const handlePrice = (temp, key) => {
    if (temp) {
      if (!isNaN(+temp.price) && key == "price") {
        return Helper?.numberFormater((+temp.price).toFixed(2))
      }
      else if (!isNaN(+temp.percent_change_24h) && key == "percentChange") {
        return Helper?.numberFormater(+temp.percent_change_24h)
      }
      else {
        return "0.00"
      }
    } else {
      return "Loading..."
    }
  }


  return (

    <div className="MainBg">
      <div className="CntLdDv">
        <div className={isActive ? "HmPg  sbMenu" : "HmPg"}>
          <Sidebar setActive={SideNavClick} />
          <div className="container">
            <div className="DfltTp">
              <div className="DfltTpIg mb-3">
                <div className="DfltNtfDv">
                  <button style={{ all: "unset" }} onClick={() =>
                    localStorage.getItem("crypto.com-access-key")
                      ? navigate("/notification")
                      : ""
                  }>
                    <img
                      src={notify}
                      alt=""
                    />
                  </button>
                  {count !== 0 ? (
                    <span className="DfltNtfCnt1">{`+${count}`}</span>
                  ) : null}
                </div>
                <button style={{ all: 'unset' }} onClick={() =>
                  localStorage.getItem("crypto.com-access-key")
                    ? SideNavClick()
                    : ""
                }>
                  <img
                    src={sidenav}
                    alt=""
                  />
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-end">
                <div>
                  <p>{homepageContent?.title}</p>
                  <h1>{homepageContent?.description}</h1>
                </div>
                <img src={cryptlogo} className="cryptlogo ml-3" alt="" />
              </div>
            </div>
            <div className="HmMrktTbl mb-3">
              <h2>Market</h2>
              <div className="HmMrktTbs">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        tabChange("1");
                      }}
                    >
                      All Markets
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        tabChange("2");
                      }}
                    >
                      Top
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          {currencyLists?.map((temp) =>
                            temp?.percent_change_24h && temp?.price ? (
                              <tr
                                key={temp?._id}
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateWallet(temp?._id)}
                              >
                                <td>
                                  <div className="CnDts">
                                    <img
                                      style={{
                                        height: "28px",
                                        width: "28px",
                                      }}
                                      src={temp?.image}
                                      className="mr-2"
                                      alt="" />
                                    <div>
                                      <h5>{temp?.currency_symbol}</h5>
                                      <h6>USDT</h6>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <Minichart
                                    chartdata={
                                      temp?.miniChartData
                                        ? temp?.miniChartData
                                        : []
                                    }
                                    chartcolor={temp?.miniChartColor}
                                  />
                                </td>
                                <td className="MrktTbsTd3">
                                  <h5 className="MrktTbsTd3H5">
                                    ${" "}

                                    {handlePrice(temp, "price")}
                                  </h5>
                                  <div className="d-flex align-items-center justify-content-end">
                                    <h5
                                      className={`MrktTbsTd3H5 ${Math.sign(
                                        +temp?.percent_change_24h
                                      ) === 1
                                        ? "TxtGrn"
                                        : "Txtdgr"
                                        }`}
                                    >

                                      {handlePrice(temp, "percentChange")}
                                      {" "}
                                      %
                                    </h5>
                                    <h6 className="ml-2 mb-2">24 Hrs</h6>
                                  </div>
                                </td>
                              </tr>
                            ) : null
                          )}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          {currencyLists?.map((temp) => (
                            <tr
                              key={temp?._id}
                              style={{ cursor: "pointer" }}
                              onClick={() => navigateWallet(temp?._id)}
                            >
                              <td>
                                <div className="CnDts">
                                  <img
                                    style={{ height: "28px", width: "28px" }}
                                    src={temp?.image}
                                    className="mr-2"
                                    alt="" />
                                  <div>
                                    <h5>{temp?.currency_symbol}</h5>
                                    <h6>USDT</h6>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                <Minichart
                                  chartdata={
                                    temp?.miniChartData
                                      ? temp?.miniChartData
                                      : []
                                  }
                                  chartcolor={temp?.miniChartColor}
                                />
                              </td>
                              <td className="MrktTbsTd3">
                                <h5 className="MrktTbsTd3H5">
                                  ${" "}

                                  {handlePrice(temp, "price")}

                                </h5>
                                <div className="d-flex align-items-center justify-content-end">

                                  <h5
                                    className={`MrktTbsTd3H5 ${Math.sign(
                                      +temp?.percent_change_24h
                                    ) === 1
                                      ? "TxtGrn"
                                      : "Txtdgr"
                                      }`}
                                  >


                                    {handlePrice(temp, "percentChange")}


                                    {" "}
                                    %
                                  </h5>
                                  <h6 className="ml-2 mb-2">24 Hrs</h6>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <div className="ArbtMineInvt">
              <div className="row">
                <div className="col-md-6">
                  <div className="ArbtMineInvt ArbtMineInvt1">
                    <h5>{homepageContent?.title_1}</h5>
                    <h4>{homepageContent?.subtitle_1}</h4>
                  </div>
                </div>
                <div className="col-md-6 pl-md-0">
                  <div className="ArbtMineInvt ArbtMineInvt2">
                    <h5>{homepageContent?.title_2}</h5>
                    <h4>{homepageContent?.subtitle_2}</h4>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6"></div>
                  </div>
                  <div className="ArbtMineInvt ArbtMineInvt3">
                    <h5>{homepageContent?.title_3}</h5>
                    <h4>{homepageContent?.subtitle_3}</h4>
                    <div style={{ maxWidth: "500px" }}>
                      <p>{homepageContent?.subdescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal1}
        toggle={toggleChat}
        modalClassName="CmnMdl ChatMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <h5>Chat with us</h5>
            <button onClick={toggleChat}
              style={{ all: "unset", cursor: "pointer" }}>
              <img
                src={closeMdl}
                alt=""
              />
            </button>
          </div>
          <div className="CmnMdlBdy chatBdy">
            <img src={comment} alt="" />
            <p>Online Service</p>
          </div>
        </ModalBody>
      </Modal>
    </div>

  );
};

export default Home;
