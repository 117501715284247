import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";

import dot from "../../assets/images/green-dot.png";
import reddot from "../../assets/images/red-dot.png";
import lftarr from "../../assets/images/left-arrow.png";
import rgtarr from "../../assets/images/right-arrow.png";
import closeMdl from "../../assets/images/close.png";
import reject from "../../assets/images/reject.gif";
import accept from "../../assets/images/accept.gif";

import { useNavigate } from "react-router-dom";

import { usePostRequest } from "../../Services/Queries";
import { QueryKeys } from "../../Services/QueryKeys";
import USER_APIS from "../../Services/APIS";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

import nodata from "../../assets/images/nodata.png";

const Notification = (props) => {
  const [activeTab, setActiveTab] = useState("1");


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const {
    loaderProperty: { setLoading },
  } = useCustomizeContext();

  const [modal, setModal] = useState(false);
  const toggleLoanNotifiy = () => setModal(!modal);

  const [loanReject, setLoanReject] = useState(false);
  const toggleLoanRejected = () => setLoanReject(!loanReject);

  const [loanAccept, setLoanAccept] = useState(false);
  const toggleLoanAccepted = () => setLoanAccept(!loanAccept);

  const [loanCancelled, setLoanCancelled] = useState(false);
  const toggleLoanCancelled = () => setLoanCancelled(!loanCancelled)

  const [notificationMessages, setNotificationMessages] = useState([]);
  const [messages, setMessages] = useState([]);

  const [currentvalue, setCurrentvalue] = useState();
  const navigate = useNavigate();

  let { mutateAsync: sendloanstatus } = usePostRequest(
    QueryKeys?.SEND_LOAN_STATUS
  );
  let { mutateAsync: notifactionStatus } = usePostRequest(
    QueryKeys?.NOTIFICATION_STATUS
  );


  const getSecureRandomNumber = () => {
    const array = new Uint32Array(1); window.crypto.getRandomValues(array); return array[0] % 100000000; // Adjust the range as needed    };
  }

  const Loanstatus = async (value) => {
    const random = getSecureRandomNumber();

    let payload = {
      reqUid: currentvalue?.reqLoanId?._id,
      loanStatus: value,
      userrandid: random
      
    };
    let postObj = {
      Api: USER_APIS?.SEND_LOAN_STATUS,
      Payload: payload,
      Type: "",
    };
    setLoading(true);
    let res = await sendloanstatus(postObj);
    if (res?.status === true) {
      toggleLoanNotifiy();
      getChatMessages({});

      setLoading(false);
      navigate("/notification");
    } else setLoading(false);
  };

  const handleNotifactionStatus = async () => {
    toggle("2");

    let postObj = {
      Api: USER_APIS?.NOTIFICATION_STATUS,
      Payload: {},
      Type: "",
    };
    setLoading(true);
    let res = await notifactionStatus(postObj);
    if (res?.status || !res?.status) {

      setLoading(false);

    }
  };

  const Opentoggle = (value) => {
    if (value.loanStatus == "Pending") {
      setCurrentvalue(value);
      toggleLoanNotifiy();
    } else if (value.loanStatus == "Approved") {
      setCurrentvalue(value);
      toggleLoanAccepted();
    } else if (value.loanStatus == "Rejected") {
      setCurrentvalue(value);
      toggleLoanRejected();
    } else if (value.loanStatus == "Cancelled") {
      setCurrentvalue(value);
      toggleLoanCancelled();
    }
  };

  let { mutateAsync: getInitateNotify } = usePostRequest(
    QueryKeys?.GET_INITATE_NOTIFY_KEY
  );
  let { mutateAsync: getMessage } = usePostRequest(QueryKeys?.GET_MESSAGE);

  useEffect(() => {
    getChatMessages({});
    handleChatMessage({});
  }, []);

  const getChatMessages = async (payload) => {
    let postObj = {
      Api: USER_APIS?.GET_INITIATE_NOTIFY,
      Payload: {
        page: 1,
        length: 20,
      },
    };
    let res = await getInitateNotify(postObj);
    if (res?.status === true) {
      setNotificationMessages(res?.data);
    }
  };

  const handleChatMessage = async (payload) => {
    let postObj = {
      Api: USER_APIS?.GET_MESSAGE,
      Payload: {
        page: 1,
        length: 20,
      },
    };
    let res = await getMessage(postObj);
    if (res?.status === true) {
      setMessages(res?.data);
    }
  };



  const handleRedirect = (messageType, cid) => {
    switch (messageType) {
      case "deposit":
        navigate(`/transactionhistory/${cid}?tab=1`);
        break;
      case "chat":
        navigate("/chat");
        break;
      case "withdraw":
        navigate(`/transactionhistory/${cid}?tab=2`);
        break;
      case "convert":
        navigate(`/transactionhistory/${cid}?tab=3`);
        break;
      default:
        break;
    }
  };


  const handleNotify = (status) => {
    if (status == 'Pending') {
      return "Loan Request"
    }
    else if (status == 'Approved') {
      return "Loan Accepted"
    }
    else if (status == 'Cancelled') {
      return "Loan Cancelled"
    }
    else {
      return "Loan Rejected"

    }
  }

  const handleNotifyClass = (status) => {
    if (status == 'Approved') {
      return "lnAct"
    }
    else if (status == 'Rejected') {
      return "lnRej"
    }
    else if (status == 'Cancelled') {
      return "lnRej"
    }
    else {
      return ""

    }
  }

  const handleLoanClass = (status) => {
    if (status == 'Approved') {
      return dot
    }
    else if (status == 'Pending') {
      return dot
    }
    else {
      return reddot
    }
  }


  return (
    <Fragment>
      <div className="MainInrBg">
        <div className="CntLdDv">
          <div className="EmailVrfyPg DeptPg NotfifyPg">
            <div className="container">

              <div className="EmailVrfyTp">
                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate("/")}>
                  <img

                    src={lftarr}

                    alt=""
                  />
                </button>
                <h4>Notification</h4>
                <div></div>
              </div>
              <div className="NotfifyBg">
                <div className="DeptTbs mb-3">
                  <Nav tabs style={{ width: "220px" }}>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Notice
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={
                          () => handleNotifactionStatus()

                        }
                      >
                        Messages
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="mt-3">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {notificationMessages?.length > 0 ? (
                        notificationMessages &&
                        notificationMessages.map((notification) => (
                          <button
                            key={notification?._id}
                            role="button"
                            tabIndex={0}
                            className="NotfifyInrDts mtBtn"
                            onClick={() => Opentoggle(notification)}
                          >
                            <div className="NotfifyInrDtsL">
                              <h4>
                                <img
                                  src={

                                    handleLoanClass(notification?.loanStatus)
                                  }
                                  className="grndt mr-2"
                                  alt=""
                                />
                                <span
                                  className={

                                    `${handleNotifyClass(notification?.loanStatus)}`
                                  }
                                >

                                  {handleNotify(notification?.loanStatus)}
                                </span>{" "}
                                <span className=" mx-2">
                                  {Helper.formatDated(notification?.reqTime)}
                                </span>
                              </h4>
                              <h5 className="text-left">UID :{notification?.fromUserDetails?.uid}</h5>
                            </div>
                            <img src={rgtarr} className="ml-2" alt="" />
                          </button>
                        ))
                      ) : (
                        <div className="NdtFnd">
                          <img src={nodata} alt="" />
                          <span>  No Data</span>
                        </div>
                      )}
                    </TabPane>
                  </TabContent>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="2">
                      {messages?.length > 0 ? (
                        <ul
                          style={{ listStyleType: "disc", paddingLeft: "20px" }}
                        >
                          {messages.map((notification) => (
                            <li
                              key={notification?._id}
                              style={{ margin: "5px 0", cursor: 'pointer' }}
                              className="NotfifyInrDts"
                            >
                              <button className="mtBtn" onClick={() => handleRedirect(notification?.messageType, notification?.currencyId)}>
                                <div className="NotfifyInrDtsL">
                                  <h4>
                                    <span>
                                      {notification?.notification || ""}
                                    </span>
                                  </h4>
                                  <h5 className="text-left">
                                    Time:{" "}
                                    {Helper.formatDated(notification?.datetime)}
                                  </h5>
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="NdtFnd">
                          <img src={nodata} alt="No Data" />
                          <span> No Data</span>
                        </div>
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal
        isOpen={modal}
        toggle={toggleLoanNotifiy}
        modalClassName="CmnMdl LnRqstMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <h5>Loan Request</h5>
            <button onClick={toggleLoanNotifiy}
              style={{ all: "unset", cursor: "pointer" }}>
              <img
                src={closeMdl}

                alt=""
              />
            </button>
          </div>
          <div className="CmnMdlBdy LnRqstBdy">
            <a href={() => false}>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </a>
            <div className="mt-3">
              <div className="LnRqstInr">
                <h5>Amount</h5>
                <h6>
                  <span>
                    {Helper.loanNumberWithCommas(
                      +currentvalue?.reqLoanId?.reqAmount
                    )}
                  </span>{" "}
                  {currentvalue?.reqCurrencyId?.currency_symbol}
                </h6>
              </div>
              <div className="LnRqstInr">
                <h5>Receiver address</h5>
                <h6>{`${currentvalue?.reqLoanId?.walletAddress.slice(0, 7)}..........${currentvalue?.reqLoanId?.walletAddress.slice(28)}`}</h6>
              </div>
              <div className="LnRqstInr">
                <h5>Created time</h5>
                <h6>{Helper.formatDated(currentvalue?.reqTime)}</h6>
              </div>
            </div>
            <div className="AccRejBtn mt-5">
              <button
                className="btn CmnBtn rejbtn"
                onClick={() => Loanstatus("reject")}
              >
                Reject
              </button>
              <button
                className="btn CmnBtn actbtn"
                onClick={() => Loanstatus("approve")}
              >
                Accept
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={loanReject}
        toggle={toggleLoanRejected}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Request Rejected</h5>

            <button onClick={toggleLoanRejected}
              style={{ all: "unset", cursor: "pointer" }}>
              <img
                src={closeMdl}

                alt=""
              />
            </button>
          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={reject} className="my-4" alt="" />
            <h4>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount :{" "}
              <span>
                {Helper.loanNumberWithCommas(
                  +currentvalue?.reqLoanId?.reqAmount
                )}
              </span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={loanAccept}
        toggle={toggleLoanAccepted}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Request Accepted</h5>
            <button onClick={toggleLoanAccepted}
              style={{ all: "unset", cursor: "pointer" }}>
              <img
                src={closeMdl}

                alt=""
              />
            </button>

          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={accept} className="my-4" alt="" />
            <h4>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount :{" "}
              <span>
                {Helper.loanNumberWithCommas(
                  +currentvalue?.reqLoanId?.reqAmount
                )}
              </span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={loanCancelled}
        toggle={toggleLoanCancelled}
        modalClassName="CmnMdl RejMdl"
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="CmnMdlHdr">
            <div></div>
            <h5>Loan Cancelled</h5>
            <button onClick={toggleLoanCancelled}
              style={{ all: "unset", cursor: "pointer" }}>
              <img
                src={closeMdl}
                alt=""
              />
            </button>
          </div>
          <div className="CmnMdlBdy RejBdy">
            <img src={reject} className="my-4" alt="" />
            <h4>
              UID : <span>{currentvalue?.fromUserDetails?.uid}</span>
            </h4>
            <h5>
              Amount :{" "}
              <span>
                {Helper.loanNumberWithCommas(
                  +currentvalue?.reqLoanId?.reqAmount
                )}
              </span>{" "}
              {currentvalue?.reqCurrencyId?.currency_symbol}
            </h5>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Notification;
