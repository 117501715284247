import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import lftarr from "../../assets/images/left-arrow.png"
import rgtarr from "../../assets/images/right-arrow.png"
import nodata from "../../assets/images/nodata.png"
import { usePostRequest } from "../../Services/Queries";
import USER_APIS from "../../Services/APIS";
import { QueryKeys } from "../../Services/QueryKeys";
import { useCustomizeContext } from "../../Services/Context";
import Helper from "../../Services/Helper";

const MyContract = (props) => {


    let navigate = useNavigate();
    let { id } = useParams()

    const {
        loaderProperty: {
            setLoading
        }
    } = useCustomizeContext();


    const [activeTab, setActiveTab] = useState('1');

    const [finishedHistory, setFinishedHistory] = useState([]);
    const [waitingHistory, setWaitingHistory] = useState([]);

    useEffect(() => {
        const storedTab = localStorage.getItem('activeTab');
        const initialTab = storedTab === '2' ? '2' : '1';
        setActiveTab(initialTab);

        const clearStorage = setTimeout(() => {
            localStorage.removeItem('activeTab');
        }, 60000);

        return () => clearTimeout(clearStorage);

    }, []);

    const toggle = tab => {
        if (tab === '1' || tab === '2') {
            setActiveTab(tab);
            localStorage.setItem('activeTab', tab);
        }
    }




    let { mutateAsync: getEntrustHist } = usePostRequest(QueryKeys?.GET_CURRENCY_KEY);

    useEffect(() => {
        getHistoryFun();
    }, []);


    const getHistoryFun = async () => {
        let postObj = {
            Api: USER_APIS?.GET_ENTRUEST_HISTORY,
            Payload: {
                page: 1,
                length: 10,
                currencyid: id
            },
            Type: ""
        }
        setLoading(true);
        let res = await getEntrustHist(postObj);
        if (res?.status === true) {
            let res1 = res?.data?.filter((temp) => temp?.executedstatus === true);
            let res2 = res?.data?.filter((temp) => temp?.executedstatus === false);
            setFinishedHistory(res1);
            setWaitingHistory(res2)
            setLoading(false);
        }
        else
            setLoading(false);
    }

    return (

        <div className="MainInrBg">
            <div className="CntLdDv">
                <div className="HmPg DeptPg">
                    <div className="container">

                        <div className="DfltTp DeptPgTp" style={{ background: 'transparent' }}>
                            <div className="DfltTpIg mb-4">
                                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => navigate(-1)}>
                                    <img src={lftarr} alt="" />

                                </button>
                                <h4 style={{ color: '#353f52' }}>My Contract</h4>
                                <div></div>
                            </div>
                            <div className="DeptTbs mb-3">
                                <Nav tabs style={{ width: '220px' }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            Wait
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            Finished
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                        <div className="mt-3">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="DeptCnfmDv">
                                        <div className="MyCntrctDv">
                                            {
                                                waitingHistory?.length > 0 ? waitingHistory?.map((temp) =>
                                                    <button className="MyCntrctDts mtBtn" key={temp?._id} onClick={() => navigate(`/mycontractdetails/${temp?._id}`)}>
                                                        <div className="MyCntrctDtsL my-1">
                                                            <img src={temp?.currencyid?.image} className="mr-2" width="28px" height="28px" alt="" />
                                                            <div>
                                                                <h4>{temp?.currencyid?.currency_symbol}/USDT<span className="mx-2">{Helper?.formatDated(temp?.createdAt)}</span></h4>

                                                                <h5 className="text-left">Waiting..</h5>
                                                            </div>
                                                        </div>
                                                        <img src={rgtarr} className="ml-2 my-1" alt="" />
                                                    </button>
                                                )
                                                    :
                                                    <div className="NdtFnd">
                                                        <img src={nodata} alt="" />
                                                        <span> No Data</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="DeptCnfmDv">
                                        <div className="MyCntrctDv">
                                            {
                                                finishedHistory?.length > 0 ? finishedHistory?.map((temp) =>
                                                    <button className="MyCntrctDts mtBtn" key={temp?._id} onClick={() => navigate(`/mycontractdetails/${temp?._id}`)} >
                                                        <div className="MyCntrctDtsL my-1">
                                                            <img src={temp?.currencyid?.image} className="mr-2" alt="" />
                                                            <div>
                                                                <h4>{temp?.currencyid?.currency_symbol}/USDT<span className="mx-2">{Helper?.formatDated(temp?.createdAt)}</span></h4>
                                                                <h5 className="text-left">{temp?.predectionStatus === false ? "Loss" : "Profit"} : <span className={`${temp?.predectionStatus === true ? "text-suc" : "text-dgr"}`}>



                                                                    {temp?.predectionStatus === false ? parseFloat(temp?.predectionValue).toFixed(2) : (parseFloat(temp?.expectedamount) - parseFloat(temp.purchaseprice)).toFixed(2)}
                                                                </span></h5>
                                                            </div>
                                                        </div>
                                                        <img src={rgtarr} className="ml-2 my-1" alt="" />
                                                    </button>
                                                )
                                                    :
                                                    <div className="NdtFnd">
                                                        <img src={nodata} alt="" />
                                                        <span> No Data</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );

}

export default MyContract;
