import React from "react";


import lftarr from "../../assets/images/left-arrow.png";
import { useNavigate } from "react-router-dom";
import { useGetRequest } from "../../Services/Queries";
import { useParams } from "react-router";
import USER_APIS from "../../Services/APIS";
import { QueryKeys } from "../../Services/QueryKeys";
import Helper from "../../Services/Helper";

const MyContractDetails = () => {
  let navigate = useNavigate();
  let { id } = useParams();



  let requestData = {
    Api: `${USER_APIS?.GET_SINGLE_ENTRUST}/${id}`,
    Query_Key: QueryKeys?.GET_SINGLE_ENTRUST,
  };

  let { data: getOneEntrustDetails } = useGetRequest(requestData);

  const singleEntrust = getOneEntrustDetails?.data;


  const displayDate = (singleEntrust, dataToFind) => {
    if (dataToFind == "createdAt") {
      return singleEntrust != null ? Helper.formatDated(singleEntrust) : "Loading..."
    }
    else if (dataToFind == "purchaseprice") {
      return singleEntrust != null ? `${Helper?.numberFormater(+singleEntrust)} ${"USDT"}` : "Loading..."
    }

    else {
      return '---'

    }

  };

  const handleData = (singleEntrust, dataToFind) => {
    if (dataToFind == "predection") {
      return singleEntrust ? "Up" : "Down"
    }
    else if (dataToFind == "marketprice") {
      return singleEntrust ? Helper?.numberFormater(+singleEntrust.toFixed(2))
        : "Loading..."
    }
    else if (dataToFind == "predectionStatus") {
      return singleEntrust ? "#57b793" : "#F54822"
    }
    else {
      return "---"
    }
  }

  const handleDeliverytime = (deliverytime) => {
    if (deliverytime == '1m') {
      return '60S'
    }
    else if (deliverytime == '2m') {
      return '120S'
    }
    else if (deliverytime == '1h') {
      return '60M'
    }
    else if (deliverytime == '24h') {
      return '1D'
    }
    else {
      return '---'

    }
  }

  const handletwentyFourHours = (executedstatus, updatedAt, createdAt) => {
    if (executedstatus != null) {
      return +executedstatus
        ? Helper?.getTwentyFourHoursFormat(updatedAt)
        : Helper?.getTwentyFourHoursFormat(createdAt)

    }
    else {
      return "Loading..."
    }
  }

  const handlePredection = (executedstatus, predectionStatus) => {
    if (executedstatus) {
      if (!predectionStatus) {
        return "Loss"
      }
      else {
        return "Profit"
      }
    }
    else {
      return "Profit / Loss"
    }
  }

  return (

    <div className="MainInrBg">
      <div className="CntLdDv">
        <div className="HmPg DeptPg">
          <div className="container">
            <div
              className="DfltTp DeptPgTp"
              style={{ background: "transparent" }}
            >
              <div className="DfltTpIg">
                <button onClick={() => navigate(-1)} style={{ all: 'unset', cursor: "pointer" }}>
                  <img src={lftarr} alt="" />

                </button>
                <h4 style={{ color: "#353f52" }}>
                  My Contract
                </h4>
                <div></div>
              </div>
            </div>
            <div className="mt-3">
              <div className="DeptCnfmDv">
                <div className="MyCntrctDvDts">
                  <h4>
                    <img
                      src={singleEntrust?.currencyid?.image}
                      className="mr-2"
                      alt=""
                    />
                    {singleEntrust?.currencyid?.currency_symbol}/USDT
                    <span className="mx-2">

                      {displayDate(singleEntrust?.createdAt, "createdAt")}
                    </span>
                  </h4>
                  <div className="MyCntrctDvDtsIr">
                    <span>Purchase Amount</span>
                    <span className="Vl">

                      {displayDate(singleEntrust?.purchaseprice, "purchaseprice")}

                    </span>
                  </div>
                  <div className="MyCntrctDvDtsIr">
                    <span>Direction</span>
                    <span className="text-suc">

                      {handleData(singleEntrust?.predection, "predection")}

                    </span>
                  </div>
                  <div className="MyCntrctDvDtsIr">
                    <span>Purchase price</span>
                    <span className="Vl">

                      {handleData(singleEntrust?.marketprice, "marketprice")}

                    </span>
                  </div>

                  <div className="MyCntrctDvDtsIr">
                    <span>Contract</span>
                    <span className="Vl">

                      {handleDeliverytime(singleEntrust?.deliverytime)}
                    </span>
                  </div>
                  <div className="MyCntrctDvDtsIr">
                    <span>


                      {handlePredection(singleEntrust?.executedstatus, singleEntrust?.predectionStatus)}
                    </span>
                    <span>
                      {singleEntrust?.executedstatus ?

                        <span
                          style={{
                            color:

                              handleData(singleEntrust?.predectionStatus, "predectionStatus")
                          }}

                        >
                          {singleEntrust?.predectionStatus === false ? parseFloat(singleEntrust?.predectionValue).toFixed(2) : (parseFloat(singleEntrust?.expectedamount) - parseFloat(singleEntrust.purchaseprice)).toFixed(2)}
                        </span>
                        :
                        <span className="Vl">
                          Waiting .....
                        </span>
                      }
                    </span>


                  </div>
                  <div className="MyCntrctDvDtsIr">
                    <span>Delivery price</span>
                    <span className="Vl">

                      {+singleEntrust?.executedstatus
                        ? Helper?.numberFormater(+singleEntrust.predectionTimePrice.toFixed(2))
                        : "Waiting ....."}
                    </span>
                  </div>



                  <div className="MyCntrctDvDtsIr">
                    <span>Delivery time</span>
                    <span className="Vl">

                      {handletwentyFourHours(singleEntrust?.executedstatus, singleEntrust?.updatedAt, singleEntrust?.createdAt)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

export default MyContractDetails;












